/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"
import buildRetriever from "../utils/slugRetriever";

const Bio = ({ authorSlug }) => {
  const data = useStaticQuery(graphql`
    query BioQuery {
      allAuthorsJson {
        edges {
          node {
            id
            name
            description
            fields {
              slug
            }
            dp {
              childImageSharp {
                fluid(maxWidth: 300) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
      placeholder: file(relativePath: {glob: "profile-pic.png"}) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const authors = data.allAuthorsJson.edges.map(({ node }) => node);
  const retrieveAuthor = buildRetriever(authors)
  const author = retrieveAuthor(authorSlug)
  return author ?
    (
      <div className="bio grid-x">
        <div className="avatar cell small-2">
          {author.dp ? <Image
            fluid={{ ...author.dp.childImageSharp.fluid, aspectRatio: 1 }}
            alt={author.name}
            imgStyle={{
              borderRadius: `50%`,
            }}
          /> :
            <Image
              fluid={{ ...data.placeholder.childImageSharp.fluid, aspectRatio: 1 }}
              alt={author.name}
              imgStyle={{
                borderRadius: `50%`,
              }}
            />
          }
        </div>
        <p className="cell small-10">
          <strong>{author.name}</strong>  <br />
          {author.description}
        </p>
      </div>
    )
    :
    null
}

export default Bio
